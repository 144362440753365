/******************************************************************************
 Unpublished work. Copyright 2025 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
******************************************************************************/
import { extendTailwindMerge } from 'tailwind-merge';
import resolveConfig from 'tailwindcss/resolveConfig';
import type { ResolvableTo, ScreensConfig } from 'tailwindcss/types/config';

import tailwindConfigFile, {
    SCREEN_BREAKPOINTS,
    type ScreenBreakpointType,
    screenBreakpointsConfig,
} from '../../tailwind.config';

const getTailwindCssConfig = () => {
    return resolveConfig(tailwindConfigFile);
};

export interface IScreenSizeEntry {
    name: string;
    min: number;
    max: number;
}

const convertToScreenSizeModel = (screenConfig: ResolvableTo<ScreensConfig>) => {
    const arr: IScreenSizeEntry[] = [];
    Object.keys(screenConfig).forEach((key, i) => {
        const minPx = Object.values(screenConfig)[i].min as string;
        const min = Number.parseInt(minPx.replace('px', ''));
        const maxPx = Object.values(screenConfig)[i].max as string;
        const max = Number.parseInt(maxPx.replace('px', ''));
        arr.push({ name: key, min, max });
    });
    return arr;
};

const matchScreenBreakpoint = (screenSizeModel: IScreenSizeEntry[], screenWidth: number) => {
    if (screenSizeModel.length === 0) {
        return { name: SCREEN_BREAKPOINTS.SM, min: 0, max: 9999 };
    }
    const result = screenSizeModel.find((p) => p.min <= screenWidth && p.max >= screenWidth);
    if (!result) {
        throw new Error(`Not defined breakpoint for width ${screenWidth}`);
    }
    return result;
};

const getBreakpointMinMax = (breakpoint: ScreenBreakpointType) => {
    const screen = screenBreakpointsConfig[breakpoint];
    if (!screen) {
        throw new Error(`Breakpoint ${breakpoint} not found in screen size config`);
    }
    const { max: maxPx, min: minPx } = screen;

    const min = Number.parseInt(minPx.replace('px', ''));
    const max = Number.parseInt(maxPx.replace('px', ''));
    return { min, max, minPx, maxPx };
};

const testResolutionWithinBreakpoint = (width: number, breakpoint: ScreenBreakpointType) => {
    const { min, max, minPx, maxPx } = getBreakpointMinMax(breakpoint);

    expect(width).to.be.within(
        min,
        max,
        `Expected screen width ${width} to be within boundaries of breakpoint ${breakpoint} (${minPx} - ${maxPx})`,
    );

    return width;
};

const isInBreakpointRange = (width: number, breakpoint: ScreenBreakpointType) => {
    const { min, max } = getBreakpointMinMax(breakpoint);

    return width >= min && width <= max;
};

const customTwMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            'font-size': ['text-notation', 'text-icon-description'],
        },
    },
});

const getClassForBreakpoint = (
    currentScreenBreakpoint: ScreenBreakpointType,
    smClass: string,
    mdClass: string,
    lgClass: string,
) => {
    switch (currentScreenBreakpoint) {
        case SCREEN_BREAKPOINTS.SM: {
            return smClass;
        }
        case SCREEN_BREAKPOINTS.MD: {
            return mdClass;
        }
        case SCREEN_BREAKPOINTS.LG: {
            return lgClass;
        }
        default: {
            throw new Error(`Unknown breakpoint ${currentScreenBreakpoint}`);
        }
    }
};

export const tailwindCssUtil = {
    getBreakpointMinMax,
    getTailwindCssConfig,
    convertToScreenSizeModel,
    matchScreenBreakpoint,
    isInBreakpointRange,
    testResolutionWithinBreakpoint,
    customTwMerge,
    getClassForBreakpoint,
};
