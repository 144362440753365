/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';

import { PdfIcon } from 'assets';

interface IDownloadButtonProps {
    datasheetUrl: string;
}

export const DownloadButton = ({
    datasheetUrl,
    ...props
}: IDownloadButtonProps &
    Omit<
        React.HTMLAttributes<HTMLButtonElement>,
        'children' | 'content' | 'onClick' | 'className'
    >) => {
    const handleClick = () => {
        siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createPDFDatasheetOpenEvent());
        window.open(datasheetUrl, '_blank', 'noreferrer');
    };

    return (
        <button {...props} className='bg-transparent' onClick={handleClick}>
            <PdfIcon />
        </button>
    );
};
