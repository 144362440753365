/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import styles from '/src/index.css?inline';
import { App } from 'App';
import { defaultTheme } from 'core';
import * as jose from 'jose';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { v6 as uuidv6 } from 'uuid';
import { PluginContextProvider } from 'webComponent/PluginContextProvider';

import { isLocalDevelopment } from 'const';
import type { HostAppNameType, RegionNameType, ThemeNameType } from 'types';
import type { SessionTokenPayload } from 'types/SessionTokenPayload';

const DEBUG = false;

interface IPluginProps {
    sessionToken?: string;
    region?: RegionNameType;
    theme?: ThemeNameType;
    analyticsDigitalProductExperience?: boolean;
    analyticsProductExcellenceProgram?: boolean;
    hostAppName?: HostAppNameType;
    hostAppVersion?: string;
}

interface IAppGeometry {
    top: number;
    left: number;
    width: number;
    height: number;
}

const testIds = {
    container: 'app-container',
} as const;

export { testIds as pluginTestIds };

export const Plugin = ({
    sessionToken,
    region,
    theme = defaultTheme,
    analyticsDigitalProductExperience,
    analyticsProductExcellenceProgram,
    hostAppName,
    hostAppVersion,
}: IPluginProps) => {
    const [key, setKey] = React.useState<string>(uuidv6());

    const [currentSessionToken, setCurrentSessionToken] = React.useState<string | undefined>(
        undefined,
    );
    const [currentSessionId, setCurrentSessionId] = React.useState<string | undefined>(undefined);
    const [currentRegion, setCurrentRegion] = React.useState<RegionNameType | undefined>(undefined);
    const [appGeometry, setAppGeometry] = React.useState<IAppGeometry | undefined>(undefined);

    const restartPlugin = () => {
        setKey(uuidv6()); // changing key will reset state of App component
    };

    const [rootRefReady, setRootRefReady] = React.useState(false);
    const rootRef = React.useRef<HTMLDivElement>(null);

    const computeGeometry = () => {
        if (!rootRef.current) {
            return;
        }
        const rect = rootRef.current.getBoundingClientRect();

        const geom: IAppGeometry = {
            left: rect.left,
            top: rect.top,
            width: rect.width,
            height: window.innerHeight - rect.top,
        };
        DEBUG && console.debug('Plugin:computeGeometry geom:', geom);
        setAppGeometry(geom);
    };

    const handleWindowResize = () => {
        DEBUG && console.debug('Plugin:handleWindowResize');
        computeGeometry();
    };

    const handleAppResize = (entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
            const { width } = entry.contentRect;
            if (width != appGeometry?.width) {
                DEBUG && console.debug('Plugin:handleAppResize setAppGeometry', width);
                // TODO: define & implement rules for resizing conflicts between container and window
                setAppGeometry((prevGeometry) => {
                    if (prevGeometry) {
                        return { ...prevGeometry, width };
                    }
                    return undefined;
                });
            }
        }
    };

    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(handleAppResize);
        if (rootRef.current) {
            computeGeometry();
            window.addEventListener('resize', handleWindowResize);
            resizeObserver.observe(rootRef.current);
            setRootRefReady(true);
        }

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            if (rootRef.current) {
                resizeObserver.unobserve(rootRef.current);
                resizeObserver.disconnect();
            }
        };
    }, [rootRef.current]);

    const verifySessionTokenUpdate = () => {
        if (sessionToken !== currentSessionToken) {
            // no token
            if (sessionToken === undefined) {
                setCurrentSessionToken(undefined);
                setCurrentSessionId(undefined);

                restartPlugin();

                return;
            }

            // new unparsable token
            let sessionTokenPayload: SessionTokenPayload;

            try {
                sessionTokenPayload = jose.decodeJwt<SessionTokenPayload>(sessionToken);
            } catch (e) {
                if (e instanceof jose.errors.JOSEError) {
                    if (isLocalDevelopment) {
                        console.debug(
                            'Session token cannot be parsed - treating as it was not provided',
                        );
                    }

                    setCurrentSessionToken(undefined);
                    setCurrentSessionId(undefined);

                    restartPlugin();

                    return;
                }

                throw e;
            }

            // new token with incorrect payload
            if (!sessionTokenPayload || sessionTokenPayload.sessionId === undefined) {
                if (isLocalDevelopment) {
                    console.debug(
                        'Session token does not contain session ID - treating as it was not provided',
                    );
                }

                setCurrentSessionToken(undefined);
                setCurrentSessionId(undefined);

                restartPlugin();

                return;
            }

            // new token with different session ID
            if (currentSessionId !== sessionTokenPayload.sessionId) {
                if (isLocalDevelopment) {
                    console.debug(
                        'Session token contains new session ID - PartQuest Plugin will be restarted',
                        `"${sessionTokenPayload.sessionId}"`,
                    );
                }

                setCurrentSessionToken(sessionToken);
                setCurrentSessionId(sessionTokenPayload.sessionId);

                restartPlugin();

                return;
            }

            // new token with the same session ID
            if (isLocalDevelopment) {
                console.debug(
                    'Session token changed, but session ID is the same - keeping the session',
                    `"${sessionTokenPayload.sessionId}"`,
                );
            }

            setCurrentSessionToken(sessionToken);
        }
    };

    const verifyRegionUpdate = () => {
        if (region !== currentRegion) {
            if (isLocalDevelopment) {
                console.debug('Region changed - PartQuest Plugin will be restarted', `"${region}"`);
            }

            setCurrentRegion(region);

            restartPlugin();
        }
    };

    // restart plugin when session ID or region changes
    React.useEffect(() => {
        verifySessionTokenUpdate();
        verifyRegionUpdate();
    }, [sessionToken, region]);

    return (
        <>
            <div ref={rootRef} data-testid={testIds.container}>
                {/* Must prevent immediate rendering, otherwise rootRef will be null */}
                {rootRefReady && appGeometry && (
                    <>
                        <style type='text/css'>{styles}</style>

                        <PluginContextProvider
                            theme={theme}
                            sessionToken={currentSessionToken}
                            region={currentRegion}
                            analyticsDigitalProductExperience={analyticsDigitalProductExperience}
                            analyticsProductExcellenceProgram={analyticsProductExcellenceProgram}
                            hostAppName={hostAppName}
                            hostAppVersion={hostAppVersion}
                            rootRef={rootRef}
                            appTop={appGeometry.top}
                            appLeft={appGeometry.left}
                            appWidth={appGeometry.width}
                            appHeight={appGeometry.height}
                            restartPlugin={restartPlugin}
                        >
                            <App key={key} />
                        </PluginContextProvider>
                    </>
                )}
            </div>
        </>
    );
};

class PartQuestPlugin extends HTMLElement {
    static readonly observedAttributes = [
        'session-token',
        'region',
        'theme',
        'analytics-digital-product-experience',
        'analytics-product-excellence-program',
        'host-app-name',
        'host-app-version',
    ];

    private readonly renderElement: HTMLDivElement;
    private root?: ReactDOM.Root;
    private getNormalizedAttribute<T>(name: string) {
        const value = this.getAttribute(name);
        return value === null ? undefined : (value as T);
    }

    private getNormalizedStringAttribute<T extends string>(name: string) {
        const value = this.getAttribute(name);
        return value === null ? undefined : (value as T);
    }

    private getNormalizedBooleanAttribute(name: string) {
        const value = this.getAttribute(name);
        return value === null ? undefined : value === 'true';
    }

    private render() {
        if (!this.root) {
            return;
        }

        const props: IPluginProps = {
            analyticsDigitalProductExperience: this.getNormalizedBooleanAttribute(
                'analytics-digital-product-experience',
            ),
            analyticsProductExcellenceProgram: this.getNormalizedBooleanAttribute(
                'analytics-product-excellence-program',
            ),
            sessionToken: this.getNormalizedStringAttribute('session-token'),
            region: this.getNormalizedAttribute<RegionNameType>('region'),
            theme: this.getNormalizedAttribute<ThemeNameType>('theme'),
            hostAppName: this.getNormalizedAttribute<HostAppNameType>('host-app-name'),
            hostAppVersion: this.getNormalizedAttribute('host-app-version'),
        };

        this.root.render(<Plugin {...props} />);
    }

    constructor() {
        super();

        this.renderElement = document.createElement('div');
        this.attachShadow({ mode: 'open' }).appendChild(this.renderElement);
    }

    // noinspection JSUnusedGlobalSymbols
    connectedCallback() {
        this.root = ReactDOM.createRoot(this.renderElement);
        this.render();
    }

    // noinspection JSUnusedGlobalSymbols
    disconnectedCallback() {
        this.root?.unmount();
    }

    // noinspection JSUnusedGlobalSymbols
    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        if (oldValue !== newValue) {
            this.render();
        }
    }
}

if (!customElements.get('partquest-plugin')) {
    customElements.define('partquest-plugin', PartQuestPlugin);
}
